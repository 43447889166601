@import '../narxcare-colors';

.end-user-agreement{
  border: 1px solid #ddd;
  padding: 1em 3em;
  font-size: 14px;

  h1{
    font-size: 24px;
  }

  h2{
    font-size: inherit;
    padding: 0;
    margin: 0;
    line-height: 1.5em;
  }

  legend{
    border: none;
    font-size: inherit;
  }

  label{
    display: inline-block;
    width: 95%;
    font-size: inherit;
  }

  .disclaimer{
    font-style: italic;
  }

  p{
    margin: .75em 0;
  }

  ul{
    margin-left: 10px;
  }

  li{
    list-style-type: '-';
    font-size: inherit;
    padding-left: .25em;
  }

  .single-check{
    height: 1.5em;
    width: 1.5em;
    margin-bottom: .5em;
  }

  input[type=checkbox]{
    width: 1.3em;
    height: 1.3em;
    margin-top: .25em;
    margin-right: .25em;
    vertical-align: top;
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid $border-color;
    display: inline-block;
    &:disabled{
      background: #d0d0d0;
      &:checked{
        background: #d0d0d0;
        border-color: #d0d0d0;
      }
    }
    &:checked{
      background: $button-color;
      border-color: $button-color;
      &:after{
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: #fff;
        font-size: 12px;
        position: relative;
        left: 2px;
        content: "\f00c";
      }
    }
  }
}
