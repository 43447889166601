.registration_pending {
  text-align:right;
  color:#A15A0B;
}

.registration_missing {
  text-align:right;
  font-weight:bold;
  color:#8B0000;
}

.welcome {
  font-size:24px;
  font-weight:bold;
}

i.form-info {
  color:#aaa;
  span{
    color:#dd0000;
  }
}

#new_rx_form {
  .required {
    font-weight:bold;
  }
}

div#drug_fields table thead tr th {
  font-weight:normal;
}

.required label.no_asterisk:after, span.no_asterisk:after {
  content: '';
}

#office_dispenser_supplementary_information{
  color: red;
  margin: 12px 12px 12px 0px;
}

span.required.validation_error_message:after{
  content: '';
}

label.override-required:after {
  content: '' !important;
}


div#edit_demographics_modal div.demographics {
  padding-bottom:1px;

  .healthcare_specialty_browse_all {
    margin-right: 144px;
  }

  .healthcare_specialty_container {
    margin-right: 20px;
  }
}

div#edit_demographics_modal div.demographics div.control-group{
  margin-bottom:0px!important;
  margin-top:8px;
}
div.or{
  width:220px;
  text-align:center;
  color:#999;
  margin-bottom:16px;
  span{
    color:#555;
  }
}

div.delegates div.delegates-top div.input-append{
  top:9px;
  position:relative;
  margin-right:9px;
  label.control-label{
    display:inline-block;
    float:left;
    height:32px;
    line-height:32px;
    margin-right:10px;
    color:#778;
  }
}
@-moz-document url-prefix() {
  div.delegates div.delegates-top div.input-append{
    label.control-label{
      position:absolute;
      float:none;
      left:0px;
      margin-left:-46px;
    }
  }
}

.delegates,
.peer_reviewers {
  max-width: 35rem;
}

#suffix-number {
  background-color: #fff;
  color: red;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}

div.person span{
  display:block;
}
div.demographics div#dea_numbers_table{
  width:315px;
  max-height:120px;
  overflow:auto;
}
div.demographics div#available_identifers{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  input[type=checkbox] {
      vertical-align: middle;
      position: relative;
      bottom: 3px;
  }
}
.modal-body div.business{
  display:block;
  border-left:0px;
}
span.phone, span.fax{
  white-space:nowrap;
}
.address_1:after{
  content:', ';
}
.address_2:after{
  content:'; ';
}

#password_requirements_list {
  margin: 0 0 10px 10px;
  list-style: none;
  padding-left: 1em;

  li:before {
    content: '\2022';
    margin-left: -10px;
    padding-right: 10px;
  }

  li.checked:before {
    content: '\2713';
    margin-left: -10px;
    padding-right: 5px;
  }
}
